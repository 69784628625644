






























































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import { Route } from "@/store/models/route";
import { Stop, RouteStop } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";

@Component({
    components:{}
})
export default class RouteStopCreateView extends Vue {
    routeStop: RouteStop = {} as RouteStop
    routeList: Route[] = []
    stopList: Stop[] = []

    async created() {
        const id = this.$route.query["id"]

        await metaStore.loadRoutesDD();
        await metaStore.loadStop();
        this.routeList = metaStore.routesList;
        this.stopList =  metaStore.stopList;

        if(id) {
            await metaStore.getRouteStopById(id);
            this.routeStop = metaStore.routeStop;
        }
    }

    get AdminRouter() {
        return AdminRouter;
    }

    async onSubmit() {
        const id = this.$route.query["id"]
        let isValid = await this.$validator.validateAll();
        if (!isValid) {
          return;
        }
        if(id) {
            await metaStore.patchRouteStop(this.routeStop);
            this.$snotify.success("RouteStop  Changed Successfully");
        }
        else{
            await metaStore.createRouteStop(this.routeStop);
            this.$snotify.success("RouteStop Created Successfully");
        }
        this.$router.push(AdminRouter.RouteStop);
    }

    onCancel() {
        this.$router.push(AdminRouter.RouteStop);
    }

    routeRemoteMethod(query: any) {
        if (query !== '') {
          setTimeout(() => {
            this.routeList = metaStore.routesList.filter(item => {
              return item.name.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.routeList = metaStore.routesList;
        }
    }

    stopRemoteMethod(query: any) {
        if (query !== '') {
          setTimeout(() => {
            this.stopList = metaStore.stopList.filter(item => {
              return item.name.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.stopList = metaStore.stopList;
        }
    }
}
